<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增商户' : '编辑商户'" append-to-body width="700px">
    <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="商户名称" prop="merName">
        <el-input v-model="form.merName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="商户编号" prop="merCode">
        <el-input v-model="form.merCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="MD5加密秘钥" prop="merMd5Key">
        <el-input v-model="form.merMd5Key" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="验签秘钥" prop="merKey">
        <el-input v-model="form.merKey" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="有效期" prop="validDate">
        <el-date-picker
          v-model="form.validDate"
          type="date"
          style="width: 250px;"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态" prop="merStatus">
        <el-radio-group v-model="form.merStatus">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" style="width: 500px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addMerchantInfo, updateMerchantInfo } from '@/api/dataPms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false, loading: false, form: {id: '',merName: '', merCode: '', merMd5Key: '',merKey:'',  merStatus:'' , validDate: '' ,remark: ''}, 
      rules: {
        merName: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        merCode: [
          { required: true, message: '请输入商户编号', trigger: 'blur' }
        ],
        merMd5Key: [
          { required: true, message: '请输入MD5加密秘钥', trigger: 'blur' }
        ],
        merKey: [
          { required: true, message: '请输入验签秘钥', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      const validDate = this.form.validDate
      this.form.validDate=parseTime(validDate)
      this.formData = { merchantInfo: this.form }
      this.params = {'content':JSON.stringify(this.formData)}
      addMerchantInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      const validDate = this.form.validDate
      this.form.validDate=parseTime(validDate)
      this.formData = { merchantInfo: this.form }
      this.params = {'content':JSON.stringify(this.formData)}
      updateMerchantInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = { id: '',merName: '', merCode: '', merMd5Key: '',merKey:'',  merStatus:'', validDate: '' ,remark: ''}
    }
  }
}
</script>

<style scoped>

</style>
