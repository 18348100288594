<template>
  <div class="app-container">
    <eHeader  :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="merName" label="商户名称"/>
      <el-table-column prop="merCode" label="商户编码"/>
      <el-table-column prop="validDate" label="有效期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.validDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.merStatus ? '启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
      <template slot-scope="scope">
        <span>{{ parseTime(scope.row.createTime) }}</span>
      </template>
      </el-table-column>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_EDIT'])" :data="scope.row" :sup_this="sup_this"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initPmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/pms/mer/header'
import edit from '@/components/pms/mer/edit'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{}
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/community/crm/configure/merchant/queryMerchantInfoByParams'
      const query = this.query
      const merName = query.merName
      const merCode = query.merCode
      const startValidDate = parseTime(query.startValidDate)
      const endValidDate = parseTime(query.endValidDate)
      this.formData = { P: this.page, pageSize: this.size }
      if (merName !== '' && merName !== null) { this.formData['merName'] = merName }
      if (merCode !== '' && merCode !== null) { this.formData['merCode'] = merCode }
      if (startValidDate !== '' && startValidDate !== null) { this.formData['startValidDate'] = startValidDate }
      if (endValidDate !== '' && endValidDate !== null) { this.formData['endValidDate'] = parseTime(endValidDate) }
      this.params = {'content':JSON.stringify(this.formData)}
      return true
    }
  }
}
</script>

<style scoped>

</style>
